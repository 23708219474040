<template>

  <div class="cardwrapper card" :id="scope + '_' + objective._id" :class = "{'activecard':isActive}" > 
    <div class="row m-2 " v-if="objectivedata.noaccess == true">
     <div class="px-2 w-100  d-flex flex-column">

        <div>
           <h4 class = "objectiveCardtitle d-flex align-items-center" >
        
             <span class = "overflow-string" style="cursor: default;">{{ objectivedata.name }}</span>
          </h4>
        </div>
       <div class="d-flex flex-row">
         <h4 class = "my-auto">
          <span class="badge badge-light" v-if="objective.noaccess == true && objective.userstatus != 'pendingjoin'">No access</span>
          <span class="badge badge-light" v-if="objective.noaccess == true && objective.userstatus == 'pendingjoin'">Approval pending</span>
        </h4>
        <div class="ml-auto" v-if="objective.isactive == true && objective.noaccess == true">
         <button type="button" class="btn btn-primary btn-request float-right disabled noshadow" disabled="disabled" v-if="objective.userstatus == 'pendingjoin'">Request access</button>
         <button type="button" class="btn btn-primary btn-request float-right" @click.prevent="$emit('onrequestaccess',objective);" v-if="objective.userstatus == ''">Request access</button>
       </div>
     </div>
   </div> 
 </div>
 <div class="d-flex flex-column card-body" v-if="objectivedata.noaccess == false">
   <!-- objective start -->

   <div class="objective_titlewrapper d-flex my-0 px-2" style ="min-height: 32px" >
     <div class="d-flex flex-column min-width-1 title">

        <div>
          <h4 @click="linktoobjectivesdescription" class="objectiveCardtitle d-flex  align-items-center asalink">            
             <span class="overflow-string">{{ objectivedata.name }}</span>                    
            <span class="iconbox"><i class="infoicon fa-sm fal fa-info-circle ml-2"></i></span>
       </h4>
        </div>
       

       <h4 class=" align-items-center" >

         <span  v-if="objective.isactive == false" class="badge badge-light" >Inactive</span> 
       </h4>

     </div>
        <!--  <div>
            <i class="fal fa-users" id="membersicon" @click="$emit('onmemberlistcall' , 'objective', objective._id, objective)"></i>
          </div>  -->
          <div class="ml-auto" v-if="objectivedata.noaccess == false">

           <!-- objective menu starts -->

          <base-dropdown class="dropleft">
           <template v-slot:dropdown-menu>
             <div class ="icon-holder">
              <span>
                <i class="far mr-0" :class ="{'fa-ellipsis-v':columnnumber == 1, 'fa-ellipsis-h':columnnumber == 2, 'fa-lg':screenType == 'mobile'}" aria-hidden="true"/>
              </span>
            </div>

          </template>


          <template v-slot:dropdown-items>
            <a
            v-if="( objective.isactive && objective.myrole == 'owner' || objective.isactive && objective.myrole == 'contributor' )"
            class="dropdown-item" 
            @click="linktoAddstatus()" 
            title="Add new status" append>
            Add status update
          </a>

          <a v-if="(!objective.isactive && objective.myrole == 'owner') && ( objective.measuretype != 'risk' && objective.measuretype != 'masterplan' && objective.measuretype != 'stakeholder')" class="dropdown-item" href="#" v-on:click="$emit('onmakeactive', 'yes', 'objective', objective._id); watingtobeactive = true">Make objective active</a>
          <a class="dropdown-item" @click="linktoobjectivesmembers" title="Objective settings">Objective members </a>
          <a v-if="objective.measuretype == 'risk'" class="dropdown-item disabled hide" disabled title="Objective settings">Objective members </a>

          <a v-if="objective.isactive && objective.myrole == 'owner'" class="dropdown-item" @click= "linktoobjectivesetings()" title="Objective settings">Objective settings</a>
        </template>
      </base-dropdown>
      <!-- objective menu ends -->
    </div>
  </div>

  
  <div class="indicator_development hasindicators">
    <!-- <statusrow :objective = "objective" :scope = "scope"/> -->

    

   <ul class="nav nav-tabs indicator_tab" role="tablist" >
     <li  v-if="objective.indicators && objective.indicators.length > 0" class=" hasleft indicatorsmove" :id= "'indicatorsmove_'+objective._id" role="presentation" style ="list-style: none;">

            <base-icon class=" moveleft icon-holder" :class = "{'disabled':!hasleft} " href="#" :disabled="!hasleft" @click.prevent="tabmove($event,objective,'left')" icon ="fas fa-caret-left"/>

   </li>
   <li class="" v-if="!objective.indicators">
       <base-icon icon ="fa fa-circle-o-notch fa-spin"/>
  </li>

  <!-- tabs loops starts -->
  <!-- data-toggle="tooltip" data-placement="top" title="09 Sep 2018" --> 
  <li v-else-if="objective.indicators && objective.indicators.length > 0" class="nav-item indicatortab showdatetooltip" :id="'indicatortab_'+objective._id+'_'+index" v-for="(indicator, index) in objective.indicators" v-bind:key="indicator.id">

   <a :class="{'active':indicator.tabactive}" 
   class="nav-link indicatorlink" 
   data-toggle="tab"
   :href="'#' +scope+ '_indicatordetails_'+objective._id+'_'+indicator._id" 
   role="tab" 
   @click.prevent="indicatorlinkclick(objective, indicator, $event)"
   >
    <icon-with-tooltips :index = "index" data-toggle="tooltip" data-placement="top" data-trigger="hover" :data-original-title="indicatordate(indicator.indicatordate)" :body = "tooltipsbody">
      <template v-slot:icon>
         <span v-html="$options.filters.statusoptionicon(indicator)"></span>
      </template>
     
   </icon-with-tooltips>
</a>


</li>
<!-- tabs loops ends -->
<li class=" hasright indicatorsmove" role="presentation" style ="margin-bottom: -1px;" v-if="objective.indicators && objective.indicators.length > 0">
    <base-icon class=" moveright icon-holder" :class = "{'disabled':!hasright} " href="#" :disabled="!hasright" @click.prevent="tabmove($event,objective,'right')" icon ="fas fa-caret-right"/>
</li>
</ul>

<div class="indicatorpanels tab-content"  v-bind:id="'indicatorpanels_'+objective._id" >
 <!-- indicator start -->

 <div v-bind:id="scope + '_indicatordetails_'+objective._id+'_'+indicator._id" v-for="indicator in objective.indicators" v-bind:key="indicator.id" class="indicatordetails tab-pane" role="tabpanel">
   <!--  start tab contens  -->

   <div class="comment-widgets">

     <div class="comment-row comment-main">
       <div class="row">
         <div class="p-2 pull-left d-flex">
           <base-avatar v-if="indicator.userdetail" :imgsrc = "indicator.userdetail.profilepic" :name = "indicator.userdetail.firstname" :newclass="'statuspic round'" > </base-avatar>
           <base-avatar v-else :silent ="true" :newclass="'statuspic round'"> </base-avatar>
         </div>
         <div class="align-self-center">
           <div v-if="indicator.userdetail" class="comment-name" v-text="indicator.userdetail.firstname + ' ' + indicator.userdetail.lastname"><span>Indicator author</span></div>

           <div v-if="!indicator.userdetail" class="comment-name"><span>User</span></div>
           <div class="comment-date " @click="setdate(indicator)">{{indicator.indicatordate | timefromnow}}</div>
         </div>
       </div>

       <div class="p-2 mb-2 comment ">
         <div class="statustext fr-view clearfix" v-html="indicator.message">indicator message text</div>
       </div>


       <div class="p-2 mt-2 mb-2 comment-measure" v-if="indicator.indicators"> 
         <p class="mb-0">Status is based on these linked objectives: </p> 
         <ul class="list-unstyled mb-0">
           <li v-for="indicator in indicator.indicators" v-bind:key="indicator.id">

            <div class = "d-flex flex-row my-1 ">
              <div style="position: relative; width:14px" class ="mr-2 text-center">

                <span v-html="$options.filters.statusoptionicon(indicator)"></span>
                
              </div>
              <span>

               <a href="#" data-toggle="tooltip" data-placement="top" title="" data-trigger="hover" :data-original-title="statusfrom(indicator.indicatordate)" class="actionprimary" v-text="indicator.measurename" v-on:click.prevent="openlinkedobjective(indicator.measureid,indicator._id)" v-if="indicator.measurename && ( !indicator.measurenoaccess && ( indicator.measureisactive || indicator.measuremyrole == 'owner') )  && scope !='newsletter'"></a>             

               <span class="text-muted" data-toggle="tooltip" data-placement="top" title="" data-trigger="hover" :data-original-title="statusfrom(indicator.indicatordate)" v-else v-text="indicator.measurename "></span>

               <template v-if="indicator.measureisactive == false">
                 <span class=" ml-1 badge badge-light">Inactive</span>
               </template>
               <template v-if="indicator.measurenoaccess == true &&  indicator.measureisactive == true">
                 <span class=" ml-1 badge badge-light" v-if="indicator.measurependingaccess == false">No access</span>
                 <span class=" ml-1 badge badge-light" v-if="indicator.measurependingaccess == true">Approval pending</span>
               </template>

             </span>
           </div>


         </li>
       </ul>  

       <basedonaggregation :haserrors="false" :aggregationmethod="indicator.aggregationmethod"></basedonaggregation>

     </div>


     <indicators :indicator ="indicator" :activeObjective="objective.isactive" :activeCockpit="activeCockpit" @showcomments="showcomments" @like="Postlike" :feedback="feedback" :loading = "loadingindicatordata"></indicators>
   </div>

   <comments :cockpit="cockpit" :activeObjective="objective.isactive" :activeCockpit="activeCockpit" :loggedinuser="loggedinuser" :objectdata="indicator" @onlike="Postlike" @replycomment="replycomment" @updated="updatepages" @oncommentposted="commentposted" @onupdatecomments="loadComments" :feedback="feedback" :commenttype="'status'" :oid="objective._id" :showalways="false"></comments>


   <!--  end tab contens  -->
 </div> 

</div>

</div>


</div>

<!-- objective end -->
</div>

        <!-- 
       <setactivemodal :modalids="'objectivessetactivemodal_'+objective._id" :activeobjective="activeobjective"  @change='setmeasureactivity'></setactivemodal>
 
       <b-modal :id="'aboutobjectivemodalpage_'+objective._id" modal-class="fulldescription simple-modal" hide-footer size="xl" >
         <template v-slot:modal-title>{{objective.name}}</template>
         <div v-html="objective.description"></div>
       </b-modal>           
 
       <memberlistmodal :modalid= '"objectivemeberlistmodal"' :members="objectivemembers"></memberlistmodal>
 
     -->

   </div>
 </template>
 <style lang="scss" scoped>


  .nav-link.indicatorlink:active{
    
  }
 @import '@/scss/abstracts/variable.scss';
 .nav-link{
  padding: 0 !important;
  outline: 0;
    outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
 /* display: none; <- Crashes Chrome on hover */
 -webkit-appearance: none;
 margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
 -moz-appearance:textfield; /* Firefox */
}

a:active, a:focus, a:visited {
 outline: 0;
 -moz-outline-style: none;
}

.cardwrapper {
  .nav-tabs .nav-link:hover{
    border-color: $N200 $N200 $NO;
  }
}

@media (min-width:$breakpoints-md){

  .objective_titlewrapper{
    .title{
      margin-top: 16px;
    }
    .dropdown{
      margin-top: 10px;
    }
  }
}
   // .activecard{
   //  -webkit-box-shadow: 0px 0px 49px -12px rgba(0,0,0,1);
   //    -moz-box-shadow: 0px 0px 49px -12px rgba(0,0,0,1);
   //    box-shadow: 0px 0px 49px -12px rgba(0,0,0,1);
   // }

   .viewer{
    cursor: zoom-in;
  }

  .disabled{
    cursor: default;
  }

  .dropdown-menu{
    z-index: 48 !important;
  }
  
</style>
<script>

</script>

<script type="text/javascript">
  import avatar from '@/views/tools/avatar'
  import basedonaggregation from '@/views/cockpit/components/basedonaggregation'
  import statusrow from "@/components/objective/statusindicatorerow"
  import Cockpitsapi from '@/services/api/cockpits'
  import {indicators,comments} from '@/components/feedback'

  import {bus} from '@/main'
  import moment from 'moment';
  import autosize from 'autosize'
  import Viewer from 'viewerjs';



  export default {
   name: 'objectivesCard',
   props:{
    cockpit:Object,
    objectivedata:Object,
    objectiveid:String,
    loggedinuser:Object,
    activeCockpit:Boolean,
    requestAccess:{
			type:Boolean,
			default:()=>false
		},
    usemenu:Boolean,
    useswipe:Boolean,
    fulldesc:Boolean,
    scope:String,
    feedback:String,
    index:Number,
    columnnumber:Number
  },

  components:{indicators,comments,avatar,basedonaggregation, statusrow},

  data(){
   return{
    objective: {},
    cursor:{position: 0, hasleft:false, hasright:false},
    activeobjective : {},
    activeindicator:{},
    watingtobeactive: false,
    errors: [],
    cockpitcursors: [],
    activetab:null,
    tables:null,
    objectivemembers : [],
    showsetaktivemodale:false,
    showinstruction : false,
    showmore : false,
    showobjectivesettings : false,
    addstatusindicator : false,
    status : { "targetvalue": "", "realizedvalue": "", "calculatedindex":1, "statusindicator":"green", "calculatedindexcolor":"", "text":"" },
    calculatedindexvalue : 0,
    isopened: false,
    loaderror : "",
    loadingindicatordata:{like:true, comments:true},
    delayshow:1000,
    perpage: 10,
    tooltipsbody:{},
    timeout:null,
    setdatecounter:0

  }

},
computed:{

  orgkey(){
      return this.$route.params.orgname ? this.$route.params.orgname : null
    },

  token(){
    return localStorage.token
  },


  screenType(){
    return this.$store.state.screenType
  },
  hasleft(){
    return this.cursor.hasleft
  },
  hasright(){
    return this.cursor.hasright
  },

  isActive(){
    if(this.$route.query.oid == this.objective._id){
      return true
    } else {
      return false
    }
  },

  tooltipdelay(){
    return '{\"show\":\"'+this.delayshow+'\",\"hide\":\"0\"}'
  }



},

watch: {


},


mounted(){

  let self = this
  moment.locale();
  

  this.initobjective()


  window.addEventListener('resize',this.ribbonbar)
       // event bus
       bus.$on('openactivetab', this.openactivetab);
       bus.$on('onobjectiveupdate', this.objectiveupdated );
       bus.$on('onreloadobjective', this.reloadobjective );
       
       bus.$on('makeactive',function(_id){
        if(self.objective._id == _id){
          self.objective.isactive = self.objective.isactive ? false : true
        }
      })


     },

     methods:{

      systemgenerated( object ){

        let toreturn = false
        if( object.measuretype && object.measuretype == "risk" ){
          toreturn = true
        }

        return toreturn

      }, 

      setdate( indicator ){

        this.setdatecounter++

        if( this.setdatecounter == 6 ){

          var newdate = prompt("Set new date", "YYYY-MM-DD")

          var setdate = new Date(newdate)
          //.getTime()

         

           if( !isNaN(setdate.getTime()) ){


          var postdata = { "iid" : indicator._id, "newdate":setdate.toISOString() }

          //.getTime()

            let self = this 
            Cockpitsapi.Objectiveindicatorsupdate( postdata )
                  .then( function(response){

                    if( response.data.ok ){

                      console.log(  response.data )
                     // location.reload()

                      //self.$emit('onaddnewindicatorsuccess', self.objective._id, response.data.ind )
                    }

                  })
                  .catch(error => console.log(error))
                  .finally( () => {
                    //this.status = 'idle'
   
                    this.setdatecounter = 0
                  })


                  
            }

            this.setdatecounter = 0

           
        }
       
      },

      statusoption(){

        return "xxxx"
      },

      reloadobjective( objectiveid ){

       if( this.objectivedata._id ==  objectiveid ){
        this.initobjective()
      }

    },
    initobjective(){

      let self = this

      this.objective = {}

      this.loadobjective( function(){

        self.loadcockpit()
        setTimeout(function(){
          self.$nextTick(()=>{


            if(self.$route.query.oid == self.objective._id ){

             if(self.$route.query.iid){
              if(self.$route.query.modal == 'no'){
                self.openactivetab(self.$route.query.oid,self.$route.query.iid)
              }

            }

            if(self.$route.query.page == 'addstatus'){

              self.$emit('onstatusupdate', self.objective.measuretype, self.objective )
            } else if(self.$route.query.page == 'osettings'){
              self.$emit('onobjectivesettings', self.objective.measuretype, self.objective)
            } else if(self.$route.query.page == 'omembers'){
              self.$emit('onmemberlistcall' , 'objective', self.objective._id, self.objective)
            } else if(self.$route.query.page == 'odescription'){
              self.$emit('onshowobjectivesdescription', self.objective)
            }
            var body = window

            var comments = document.getElementById(self.scope+'_'+self.objective._id);
             // console.log('comments', comments)

             if( comments ){

              var bounding = comments.getBoundingClientRect()
              if (!(
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.right <= (body.innerWidth || document.documentElement.clientWidth) &&
                bounding.bottom <= (body.innerHeight || document.documentElement.clientHeight)
                )){

                
                comments.scrollIntoView({block: 'start'})
              

            } 
          }
        }

      })
          var card = $('#'+self.scope+ '_' + self.objective._id)
          self.tooltipsbody = $('.indicator_tab', card)
          


        },300);

      })
      this.loadingindicatordata = {like:false, comments:false} 

    },
    linktoAddstatus(){



      let self = this

      Cockpitsapi.Objective( { "iid":this.objective._id, "token":self.token, "context":this.orgkey } )
      .then( function(response){

        self.objective = response.data.measure

        self.$emit('onstatusupdate', self.objective.measuretype, self.objective )
        self.$func.addQuery({page:'addstatus', oid:self.objective._id}, self.$route, self.$router)

      })

      
      
    },

    linktoobjectivesetings(){
      this.$emit('onobjectivesettings', this.objective.measuretype, this.objective)

      this.$func.addQuery({page:'osettings', oid:this.objective._id}, this.$route, this.$router)
    },

    linktoobjectivesmembers(){
      this.$emit('onmemberlistcall' , 'objective', this.objective._id, this.objective)
      this.$func.addQuery({page:'omembers', oid:this.objective._id}, this.$route, this.$router)
    },

    linktoobjectivesdescription(){
      this.$emit('onshowobjectivesdescription', this.objective)
      this.$func.addQuery({page:'odescription', oid:this.objective._id}, this.$route, this.$router)

    },

    openactivetab(oid,iid){


      let self = this
      if(this.objective._id == oid){

        // get active indicator and load likes and comments for it
        for( var i in this.objective.indicators ){
          if( this.objective.indicators[i]._id == iid ){
            this.activeobjective = this.objective
            this.loadComments( this.objective.indicators[i] )
            this.loadLikes(  this.objective.indicators[i] )      

          }
        }

       // console.log( this.objective.indicators.findIndex( function( obj ){ return obj._id == iid  }), "ccc");        

       var el = '#'+this.scope+'_indicatordetails_'+ oid + '_' +iid
       var link = $('a[href="' + el + '"]')
       

          //console.log(  , "pp") 
          //$('a[href="linked-index_indicatordetails_'+ link + '_' +id+'"]').parent('.indicatortab')
          $(link).tab('show')
          

          if( this.objective.indicators ){


            var str = '#'+self.scope + '_' + self.objective._id
            var card = $(str);
            var arrow = $('#indicatorsmove_'+self.objective._id).width()
            var w = card.innerWidth() - arrow*2;
            var icons = arrow+2
            var perpage = self.perpage < Math.floor(w/icons) ? self.perpage: Math.floor(w/icons);
            var index = this.objective.indicators.findIndex(x => x._id === iid )

            this.activeindicator = this.objective.indicators[index]

             var parent = '#'+ this.scope + '_' +oid
             var start = $( '.indicatortab', parent).index( $( '.indicatorlink.active', parent).parent('.indicatortab') )

             var end = start + perpage
             var tabs = $( '.indicatortab ', parent )

             if( tabs.length > perpage ){
              if(tabs.length > perpage + index){
                this.cursor.hasleft = true; 
              }
              if(  0 < perpage - index){
                this.cursor.hasright = true;
              }

              if( end > tabs.length ){
                end = tabs.length
              }

              if( (end - start) < perpage ){
                var diff = perpage - (end - start)
                start = start - diff
              }

              tabs.addClass("hide")
              tabs.slice( start, end ).removeClass("hide")

            }

        }

        Cockpitsapi.Tabclicked( iid, self.token)
      }

    },


    openlinkedobjective(measureid, indicatorid){


      this.$router.push({name: this.$route.name, query: { oid: measureid, iid: indicatorid, modal: 'yes'}})
      this.$emit('onopenmeasureinmodal', measureid, indicatorid)
        // bus.$emit('modalobjectiveshown',measureid, indicatorid)
      },
      loadobjective( callback ){       

        let self = this 

        //this.objective = {}
        this.loaderror = ""

        Cockpitsapi.Objective( { "iid":this.objectiveid, "token":self.token, "context":this.orgkey } )
        .then( function(response){

            //console.log( response.data , "vvv")

            if( response.data.ok ){

              self.loaderror = ""

              self.objective = response.data.measure

              //self.objective.myrole = "owner"
             // self.objective.noaccess = false
/////////////
              //console.log( response.data.measure._id, "llllllllll")


             //indicator.comments = response.data.comments
             //indicator.commentscount = response.data.numcomments
           }else{

           }

         })
        .catch( function(){
          self.loaderror = "yes"
        })
        .finally( () => {
         if( callback ){
          callback()    
        }
      })

      },

      

      

      statusfrom(indicator){
        // console.log(indicator.replace('.','-').replace('.','-').replace(' ',''))
        return "Status from " + moment(indicator).format("DD MMM, YYYY")
      },

      indicatordate(indicator){
        // console.log(indicator.replace('.','-').replace('.','-').replace(' ',''))
        return moment(indicator).format("DD MMM, YYYY")
      },

      openmeasureinmodal( objective, indicator ){
       this.$emit( 'onopenmeasureinmodal', objective, indicator)
     },

     objectiveupdated( objectiveid, indicator ){

       var self = this;

       if( this.objective._id == objectiveid ){
        console.log('bus test',objectiveid, indicator)
        this.objective.indicators.push(indicator)

        setTimeout( 
         function(){

          var parent = $('#'+ self.scope + '_' +objectiveid)

           self.loadcockpit( 

            function(){

              $('.indicatorlink', parent ).removeClass('active').last().click()
            }

            
/*
            function(){ 
/
             var parent = $('#'+ self.scope + '_' +objectiveid)
             var lis = $( '.nav-tabs .indicatortab' , parent )
                 //var li = $(e.currentTarget).parent('.indicatortab')
                 var next = $('.nav-tabs' , parent ).next('.indicatorpanels')

                 var tabpos = lis.length - 1

                 $('.indicatorlink').removeClass('active')

                 $($(lis.eq(tabpos)).find('.indicatorlink')).tab('show')
                 
                 next.removeClass('hide')
                 
                 var parentelement = document.getElementById(next.prop("id"));

                 $('.active', '#indicatorpanels_'+objectiveid ).removeClass('active')

                 var panel = $('#'+ self.scope + '_indicatordetails_' +objectiveid+'_'+indicator._id).addClass('active')

                 // wait for the panel to load then parse all images to handle viewer
                 setTimeout( function(){
                  $('img', '#index_indicatordetails_' + objectiveid + '_' + indicator._id + ' .statustext').on('click', function( i,o ){
                    self.attachviewer( i.currentTarget )
                  })

                  autosize($('.autogrow'));
                }, 100) 
                 self.$func.addQuery({oid:objectiveid,iid:indicator._id, modal:'no'}, self.$route, self.$router)

               }*/

               ); 
         }, 50 )

      }

    },

    attachviewer( img ){

      let self = this

      this.viewer = new Viewer( img, {
        title : false,
        toolbar: {
          zoomIn: 1,
          zoomOut: 1,
          rotateLeft: 1,
          rotateRight: 1
        },
        movable : true,
        inline: false,
        button: false,
        navbar: false,
        viewed() {
            //viewer.zoomTo(0);
          },
          hidden(){
            self.viewer.destroy()
          },
        }).view(0);

    },


    loadcockpit( callback ){


     var self = this;
     var tabid = self.objectiveid
     var card =$("#"+ this.scope + '_' +tabid)
     var tagets=$('a[data-toggle="tab"]',card); 

     for (var i=0; i<tagets.length;i++){

       tagets[i.toString()].addEventListener("click", function(e) {


        var parent = $('#' + self.scope + '_' + tabid )
        var isopened = $(e.target).parents('.nav-tabs').hasClass("opened")


        if(self.activetab !== null && typeof( parent.find('.indicatorlink.active').attr('href')) !== 'undefined'){
               // console.log(self.activetab)
               self.objective.indicators.find( x => x._id == parent.find('.indicatorlink.active').attr('href').slice( parent.find('.nav-link.indicatorlink.active').attr('href').lastIndexOf('_')+1, parent.find('.nav-link.indicatorlink.active').attr('href').length)).showcomments = false;

             }

              // e.preventDefault()

              if(self.activetab  == e.currentTarget){

               $(e.currentTarget, card).parents('.nav-tabs').removeClass("opened")
               //$(self.activetab, card).removeClass("active") 
               
               $('#indicatorpanels_'+tabid, card).toggleClass('hide')
               $('.indicatorpanels_'+tabid, card).toggleClass('hide')

               if( isopened ){
                 //$(e.target, card).parents('.nav-tabs').next('.tab-content').find('.active').removeClass("active")
                 e.preventDefault()
                 e.stopPropagation()
               }else{
               // console.log( $(e.target).parents('.nav-tabs').next('.tab-content').find('.active'))
               $(e.target, card).parents('.nav-tabs').next('.tab-content').find('.active').addClass("active")


             }
           }else{


           }



         });

     }


     tagets.on('shown.bs.tab', function (e) {
      var tab =  $($(e.target).attr('href'))
      tab.addClass('active')
      var lis = $( '.indicatortab' , $(e.currentTarget).parents('.nav-tabs') )
      var li = $(e.currentTarget).parent('.indicatortab')
      var next = $(e.currentTarget).parents('.nav-tabs').next('.indicatorpanels')

      next.removeClass('hide')
      var tabpos = lis.index(li)            


    })

     tagets.on('shown.bs.tab', function (e) {

      var x = e.target.href.split('#')
      var parts = x[1].split('_')





      $(e.target).parents('.nav-tabs').addClass("opened")
      self.activetab = e.target


    }); 

     this.ribbonbar();

     if( callback ){
       callback()
     }

     this.$emit('onobjectiveloaded', this.objective._id)

   },

   commentposted( indicator, comments, commenttype, iid, numcomments ){          

     if( commenttype == "comment"){

       for( var i in indicator.comments ){
         if( indicator.comments[i]._id == iid ){
           indicator.comments[i].comments = comments
         }         
       }

     }else{

       indicator.comments = comments

     }

     indicator.commentscount = numcomments 

     this.updatepages()
   },

   loadComments(indicator){



     let self = this 
     Cockpitsapi.Comments( { context:this.orgkey, "iid":indicator._id, "type":"status", "token": this.token} )
     .then( function(response){

       if( response.data.ok && response.data.comments != null){
         indicator.comments = response.data.comments
         indicator.commentscount = response.data.numcomments
         
       }

     })
     .catch(error => console.log(error))
     .finally( () => {
                   //do everything
                   self.loadingindicatordata.comments = false
                   //self.loadLikes( indicator )
                 })


   },
   loadLikes(indicator){

     let self = this 
     Cockpitsapi.Likes( { "iid":indicator._id, "type":"status", "token": this.token } )
     .then( function(response){

       if( response.data.ok ){
         indicator.likescount = response.data.numlikes
         indicator.dislikescount = response.data.numdislikes
         indicator.vote = response.data.hasvoted
         
       }

     })
     .catch(error => console.log(error))
     .finally( () => {
      self.loadingindicatordata.like = false
                   //do everything
                 })


   },



   replycomment(indicator,cid,commenttype,issilent,message){ 

     var self = this
     Cockpitsapi.Postcomment({"context":this.orgkey, "cockpitid":this.cockpit._id,"iid":cid,"commenttype":commenttype,"issilent":issilent,"message":message,"t":this.token,"action": "postcomment"})
     .then( function(response){
       if( response.data.ok ){          
        self.loadComments(indicator)
      }

    })
     .catch(error => console.log(error))
     .finally( () => {

               //do everything
               //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
             })

   },

   Postlike(type, object , action){

     var self = this

     Cockpitsapi.Issuealike( type,object._id,action,this.token,this.cockpit._id )
     .then( function(response){
       if( response.data.ok ){       
        object.likescount = response.data.numlikes
        object.dislikescount = response.data.numdislikes
        object.vote = response.data.hasvoted
      }

    })
     .catch(error => console.log(error))
     .finally( () => {

               //do everything
               //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }

             })

   },

         ribbonbar : function(){ //counts tab, if more than 10 hide other links 
          let self = this
          
          if( self.objective.indicators && self.objective.indicators.length){

            let length =  self.objective.indicators.length -1
            var str = '#'+self.scope + '_' + self.objective._id
            var card = $(str);
            var arrow = $('#indicatorsmove_'+self.objective._id).width()
            var w = card.innerWidth() - arrow*2;
            var icons = arrow+2
            var perpage = self.perpage < Math.floor(w/icons) ? self.perpage: Math.floor(w/icons);

              



              var items = $('#' + self.scope + '_' +self.objective._id).find(".indicator_tab" );
              var position = self.cursor.position;

              if(self.objective.indicators.length > perpage ){

               self.cursor.position = self.objective.indicators.length -  perpage;
               self.cursor.hasleft = true;


             } else if ( self.objective.indicators.length <= perpage){
              self.cursor.hasleft = false;
              self.cursor.hasright = false;
              self.cursor.position = 0;


            }


            items.each( function( i, o ){                

             var tabs = $('.indicatortab', o )

             if( tabs.length + 1 > perpage){
                     // console.log('tabs: ',tabs ) 
                     position = tabs.length - perpage;
                     
                     tabs.addClass("hide")
                     tabs.slice( position, tabs.length ).removeClass("hide")

                   } else{
                    tabs.removeClass("hide")
                  }         

                })  

          }

        },

        moveArrow(objective, indicator, dir){ 

        },

        indicatorlinkclick : function( objective, indicator, ev ){

         // alert('')


          let self = this
          if(this.activeindicator == indicator){

           $(ev.target).parents('.indicatorlink').removeClass('active') 
           // console.log($(ev.target).parents('.nav-tabs') )

           document.getElementById('homelogo').focus()

            //:id="'#tab_' +scope+ '_indicatordetails_'+objective._id+'_'+indicator._id" 
            
            clearTimeout(self.timeout)
            self.$func.removeQuery(['oid','iid','modal'], self.$route, self.$router)
            this.activeindicator ={}

          }else{
            this.activeindicator = indicator
            this.loadComments(indicator)
            this.loadLikes( indicator )

            self.loadingindicatordata = {like:true, comments:true}

            clearTimeout(self.timeout)

            self.timeout = setTimeout( function(){
              Cockpitsapi.Tabclicked( indicator._id, self.token)
            }, 1800 )

            

               //

               setTimeout( function(){
                  //console.log( $('img', '#index_indicatordetails_' + objective._id + '_' + indicator._id + ' .statustext') )
                //   let img = $('img', '#index_indicatordetails_' + objective._id + '_' + indicator._id + ' .statustext')
                //   let src = null

                //   $('img', '#index_indicatordetails_' + objective._id + '_' + indicator._id + ' .statustext').off('error').on('error', function( obj ){
                //     obj.target.src = '/static/img/imgbroken.png'
                //     obj.target.style = ''
                //   })

                //  / img[0].src = img[0].src + '?' + Math.random()

                  $('img', '#index_indicatordetails_' + objective._id + '_' + indicator._id + ' .statustext').off('click').on('click', function( i,o ){
                    self.attachviewer( i.currentTarget )
                  }).addClass('viewer')

                  autosize($('.autogrow'));

                }, 100)

               self.$func.addQuery({oid:objective._id,iid:indicator._id, modal:'no'}, self.$route, self.$router)

             }
           },



           tabmove : function( ev, objective, moveto, movetoablselut ){
            let self = this

            let length =  self.objective.indicators.length -1
            var str = '#'+self.scope + '_' + self.objective._id
            var card = $(str);
            var arrow = $('#indicatorsmove_'+self.objective._id).width()
            var w = card.innerWidth() - arrow*2;
            var icons = arrow+2
            var perpage = self.perpage < Math.floor(w/icons) ? self.perpage: Math.floor(w/icons);
           

            if(!movetoablselut){
             if( moveto == 'left' && this.hasleft){

               this.cursor.position = this.cursor.position - 1;

               this.cursor.hasright = true

               if( this.cursor.position <= 0 ){
                 this.cursor.position = 0
                 this.cursor.hasleft = false                  
               }
             }

             if( moveto == 'right' && this.hasright ){
               this.cursor.position = this.cursor.position + 1;

               this.cursor.hasleft = true;

               if( this.cursor.position > this.objective.indicators.length ){

                 this.cursor.position = this.objective.indicators.length - perpage
                 this.cursor.hasright = false

               }else{

                 if( (this.cursor.position + perpage) >= this.objective.indicators.length  ){

                   this.cursor.position = this.objective.indicators.length - perpage
                   this.cursor.hasright = false
                 }

               }
             }
           } 
               //if( $(ev.currentTarget).parents('.indicator_tab').siblings('.indicatorpanels').prop("id") == this.swiperwrapper ){
                //this.swiper.slide( objective.cursor.position,0)
                  // alert('hello')
                //}
                if(ev){             
                  var parent = $(ev.currentTarget).parents('.indicator_tab')
                }else {
                  var parent = $('.indicator_tab',card)
                }
                var tabs = $('.indicatortab', parent ) 

                var activetabe = $(tabs.find('.active').parent())
                var numofactivetabe = tabs.index(activetabe)
                
                if(numofactivetabe - this.cursor.position > perpage -1 && numofactivetabe >= 0 ){
                  tabs[numofactivetabe-1].firstChild.click()
                } else if(numofactivetabe <  this.cursor.position &&  numofactivetabe >= 0 ){
                  tabs[numofactivetabe+1].firstChild.click()
                } 
                if( tabs.length > perpage ){

                 tabs.addClass("hide")
                 tabs.slice( this.cursor.position, this.cursor.position +perpage ).removeClass("hide")

               } 

             },

             cockpitactivityconfirm : function( toactive ){
               this.cockpit.isactive = toactive

               $('#cockpit_setactivity').modal('hide')

             },

             measureactivityconfirm : function( toactive ){

               this.activeobjective.isactive = toactive

               $('#objective_setactivity').modal('hide')

             },

             setmeasureactivitymodal : function( objective, toactive ){

               this.activeobjective = objective;
               
             },

             setmeasureactivity: function(){
               if(this.objective.isactive){
                 this.objective.isactive = false
                 Cockpitsapi.Makeactive({'setto':'no','cid':this.objective._id})
               }else if(!this.objective.isactive){
                 this.objective.isactive = true
                 Cockpitsapi.Makeactive({'setto':'yes','cid':this.objective._id})
               }

             },

             tabs : function(){


               var numbertabs = 7;

               var uls = $('.indicator_tab');

               uls.each( function( i,o ){
                 $( '.indicatortab', o ).slice( numbertabs ).addClass('hide')
               })

             },

             showcomments : function( indicator ){

              let self = this

              if( indicator.showcomments == true ){
               indicator.showcomments = false
               autosize()
             }else{
               indicator.showcomments = true
             } 

             this.$nextTick(function() {
              this.updatepages()                 
            })
           },

           show_objectivedesc(){


              //this.$em
              $('#aboutobjectivemodalpage').modal('show');              
            },

            show_objectivestatusadd(){
             this.addstatusindicator = true 
           },

           show_objectivestatusaggregatoradd(){
             $('#aggregatoraddstatus').modal('show');    
           },

           updatepages(){
               //var indicatorheight = $('#indicatorpanels_'+this.objective._id+' .indicatordetails.active').height()
               //$('#indicatorpanels_'+this.objective._id).height(indicatorheight +'px')
             }

           },
           created () {
           },
           destroyed () {

           },
           updated(){

           }
         }

       </script>
